<template>
  <section
    id="pudin"
  >
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-row
            align="center"
            justify="center"
          >
            <v-card
              v-for="(product, i) in productos"
              :key="i"
              class="mx-auto mt-3"
              width="580"
              elevation="24"
            >
              <v-carousel
                hide-delimiter-background
                delimiter-icon="mdi-minus"
                height="630"
              >
                <v-carousel-item
                  container
                  v-for="(imagen, n) in product.imagenes"
                  :key="n"
                  :src="imagen.src"
                  :lazy-src="imagen.src"
                >
                </v-carousel-item>
              </v-carousel>
              <v-card-title>
                {{ product.nombre }}
              </v-card-title>
              <v-card-subtitle>
              </v-card-subtitle>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-3 d-none d-lg-block d-print-block"
                      icon
                      @click="product.dialog = true"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-btn
                      color="orange"
                      fab
                      small
                      >
                        <v-icon color="white">mdi-fullscreen</v-icon>
                      </v-btn>
                    </v-btn>
                  </template>
                  <span>Pantalla completa</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      @click="product.mostrar = !product.mostrar"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-btn
                      color="primary"
                      fab
                      small
                      >
                        <v-icon color="white">{{ product.mostrar ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                      </v-btn>
                    </v-btn>
                  </template>
                  <span>Descripción</span>
                </v-tooltip>
              </v-card-actions>
              <v-expand-transition>
                <div v-show="product.mostrar">
                  <v-divider></v-divider>
                  <v-card-text>
                    {{ product.des }}
                   <span v-html="product.disp"></span>
                  </v-card-text>
                </div>
              </v-expand-transition>
              <v-dialog
                v-model="product.dialog"
                :key="product.disp"
                fullscreen
                transition="fade-transition"
              >
              <v-card
                color="grey darken-4"
                width="1080"
                dark
              >
                <v-app-bar
                  color="primary"
                  fixed
                  flat
                >
                <h2>{{product.nombre}}</h2>
                  <v-spacer />
                  <v-btn
                    class="mx-1 mx-md-3"
                    icon
                    small
                    @click="product.dialog = !product.dialog"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-app-bar>
                  <v-responsive min-height="100vh">
                    <v-container
                      class="pa-0 fill-height"
                      fluid
                    >
                      <v-carousel
                        hide-delimiters
                        height="950"
                      >
                        <v-carousel-item
                          v-for="(pic, j) in product.imagenes"
                          :key="j"
                        >
                          <v-img
                            contain
                            :aspect-ratio="16/9"
                            :src="pic.src"
                            :lazy-src="pic.src"
                            :alt="pic.alt"
                          ></v-img>
                        </v-carousel-item>
                      </v-carousel>
                    </v-container>
                  </v-responsive>
                </v-card>
              </v-dialog>
            </v-card>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
  import cabCorte from '@/assets/principal.jpg'
  import cabCorte2 from '@/assets/cabCorte2.jpg'
  import cab1000 from '@/assets/cab_1000.jpg'
  import cab10002 from '@/assets/cab_1000_2.jpg'
  import cab1500 from '@/assets/cab_1500.jpg'
  import cab270 from '@/assets/cab_270.jpg'
  import cab430 from '@/assets/cab_430.jpg'
  import cab180 from '@/assets/cab_180.jpg'
  import cabSg from '@/assets/cab_sg.jpg'
  import centCorte from '@/assets/centCorte.jpg'
  import centCorte2 from '@/assets/centCorte2.jpg'
  import cent1000 from '@/assets/cent1000.jpg'
  import cent270 from '@/assets/cent270.jpg'
  import cent2 from '@/assets/cent2.jpg'
  import mpCorte from '@/assets/mpCorte.jpg'
  import mp1000 from '@/assets/mp1000.jpg'
  export default {
    name: 'Pudin',
    metaInfo: { title: 'Pudin' },
    data: () => ({
      productos: [
        {
          nombre: 'Pastel de pescado de roca',
          dialog: false,
          mostrar: false,
          des: 'El pastel de pescado de roca es uno de los platos preparados más populares en la carta de muchos restaurantes de España. Sirve como aperitivo con una tosta de pan crujiente. Como primer plato, espectacular en cualquier comida o cena. Se puede presentar en lonchas con un poco de mayonesa y pan tostado para untar.',
          disp: '<br>Diponibilidad<br><ul><li>1500g: Caja de 1 unidad</li><li>1000g: Caja de 1 unidad</li><li>430g: Caja de 6 unidades</li><li>270g: Caja de 10 unidades</li><li>180g: Caja de 8 unidades</li><li>Sin gluten: Caja de 1 unidad</li></ul>Conservación: entre 2ºC y 8ºC<br>Caducidad: 6 meses',
          imagenes: [
            {
              src: cab1500,
              alt: 'Tarrina de 1500 gramos de pescado de pez de roca',
            },
            {
              src: cab1000,
              alt: 'Tarrina de 1000 gramos de pescado de pez de roca',
            },
            {
              src: cab10002,
              alt: 'Tarrina de 1000 gramos de pescado de pez de roca',
            },
            {
              src: cab430,
              alt: 'Tarrina de 430 gramos de pescado de pez de roca',
            },
            {
              src: cab270,
              alt: 'Tarrina de 270 gramos de pescado de pez de roca',
            },
            {
              src: cab180,
              alt: 'Tarrina de 180 gramos de pescado de pez de roca',
            },
            {
              src: cabSg,
              alt: 'Tarrina de 1000 gramos de pescado de pez de roca sin glúten',
            },
            {
              src: cabCorte,
              alt: 'Presentación de corte de pudin de pez de roca, impecable',
            },
            {
              src: cabCorte2,
              alt: 'Corte de pudin de pez de roca presentado a su más puro estilo',
            },
          ],
        },
        {
          nombre: 'Pudin de Centollo',
          dialog: false,
          mostrar: false,
          des: 'Un combinado de merluza y centollo proporcionan un sabor excelente a este producto. Sobre tosta de pan fina acompañado de mayonesa se saca todo el provecho a su fantástico sabor.',
          disp: '<br>Diponibilidad<br><ul><li>1000g: Caja de 1 unidad</li><li>270g: Caja de 10 unidades</li></ul>Conservación: entre 2ºC y 8ºC<br>Caducidad: 6 meses',
          imagenes: [
            {
              src: cent1000,
              alt: 'Corte de centollo con presentación exquisita y profesional por cantdelimar',
            },
            {
              src: cent270,
              alt: 'Nuestras tarrinas de 270 gramos para momentos ocasionales por cantdelimar',
            },
            {
              src: cent2,
              alt: 'Nuestras dos principales tarrinas para el pudin de centollo',
            },
            {
              src: centCorte,
              alt: 'Corte de pudin de centello, una textura excelente',
            },
            {
              src: centCorte2,
              alt: 'Exquisito plato presentado con corte de pudin de centollo',
            },
          ],
        },
        {
          nombre: 'Pudin de Merluza con Pimientos',
          dialog: false,
          mostrar: false,
          des: 'Un excelente combinado de diferentes delicias del mar para dar un sabor único y esquisito al paladar del cliente.',
          disp: '<br>Diponibilidad<br><ul><li>1000g: Caja de 1 unidad</li></ul>Conservación: entre 2ºC y 8ºC<br>Caducidad: 6 meses',
          imagenes: [
            {
              src: mp1000,
              alt: 'Presentación tarrina de merluza con piminetos y plato por cantdelimar',
            },
            {
              src: mpCorte,
              alt: 'Corte de pudin de merluza con piminetos por candelimar',
            },
          ],
        },
      ],
    }),
  }
</script>
